<template>
  <footer class="jw-footer jw-footer--wrap">
    <div class="jw-footer--inner">
      <p class="jw-footer__desc">
        경기도 성남시 분당구 하오개로344번길2(운중동)<br />
        대표자:김승회 | 고유번호:506-82-79689 | 통신판매신고번호:2020-성남수정-1229<br />
        COPYRIGHT &copy; 2019 위드지저스미니스트리. ALL RIGHT RESERVED.<br />
        위드지저스미니스트리 이메일: <a href="mailto:wjm@wjm.kr">wjm@wjm.kr</a>, 연락처:031-753-2703
      </p>
      <v-spacer />
      <img src="~/assets/images/logo-footer.png" width="128" alt="Journal with Jesus logotype" class="jw-footer__logo" />
    </div>
  </footer>
</template>

<script setup></script>
